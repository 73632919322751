@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  @font-face {
    font-family: "nexa-regular";
    src: url("/public/fonts/nexa/NEXAREGULAR.ttf") format("truetype");
    font-weight: normal;
  }
  @font-face {
    font-family: "nexa-bold";
    src: url("/public/fonts/nexa/NEXABOLD.OTF") format("truetype");
    font-weight: bold;
  }

  @font-face {
    font-family: "nexa-light";
    src: url("/public/fonts/nexa/NEXALIGHT.OTF") format("truetype");
    font-weight: light;
  }

  @font-face {
    font-family: "nexa-light";
    src: url("/public/fonts/nexa/NEXALIGHT.OTF") format("truetype");
    font-weight: light;
  }

  body {
    /* font-family: 'nexa-light', 'nexa-bold' */
  }
}
